<template>
  <div ref="videoContainer" @scroll="handleScroll" class="video-container">
    <VideoList :videos="videos" />
    <div v-if="loading" class="loading-indicator">Loading more videos...</div>
  </div>
</template>

<script>
import VideoList from '@/components/VideoList.vue';
import { getPopularVideos } from '@/api/youtube';

export default {
  data() {
    return {
      videos: [],  // Array für die Videos
      nextPageToken: null, // Für die Paginierung
      loading: false, // Indikator für das Laden neuer Videos
    };
  },
  methods: {
    async fetchPopularVideos() {
      const response = await getPopularVideos();
      this.videos = response.data.items;
      this.nextPageToken = response.data.nextPageToken; // Setze den nextPageToken

      this.checkIfLoadMoreNeeded(); // Überprüfe, ob zusätzliche Videos sofort geladen werden müssen
    },
    async loadMoreVideos() {
      if (this.loading || !this.nextPageToken) return; // Verhindere doppeltes Laden
      this.loading = true;
      try {
        console.log("LAOD MORE VIDEOS")
        const response = await getPopularVideos(this.nextPageToken); // Hol die nächsten Videos
        this.videos.push(...response.data.items); // Füge die neuen Videos zum Array hinzu
        this.nextPageToken = response.data.nextPageToken; // Update nextPageToken
      } catch (error) {
        console.error("Fehler beim Laden neuer Videos:", error);
      } finally {
        this.loading = false; // Ladeindikator zurücksetzen
        this.checkIfLoadMoreNeeded(); // Prüfen, ob noch weitere Videos geladen werden müssen
      }
    },
    handleScroll() {
      const container = this.$refs.videoContainer;
      const bottom = container.scrollTop + container.clientHeight >= container.scrollHeight;
      if (bottom) {
        this.loadMoreVideos(); // Lade mehr Videos, wenn wir am Ende sind
      }
    },
    checkIfLoadMoreNeeded() {
      // Überprüfen, ob der gesamte Inhalt kleiner ist als der sichtbare Bereich, und dann sofort mehr laden
      const container = this.$refs.videoContainer;
      if (container.scrollHeight <= container.clientHeight) {
        this.loadMoreVideos(); // Lade mehr Videos, wenn der Container nicht hoch genug ist
      }
    }
  },
  mounted() {
    this.fetchPopularVideos();  // Beim Laden der Seite die populären Videos holen
  },
  components: {
    VideoList,
  },
};
</script>

<style scoped>
.video-container {
  overflow-y: auto; /* Scrollbar aktivieren, wenn der Container zu hoch ist */
  height: 100vh; /* Container-Höhe anpassen */
  display: flex;
  justify-content: center;
}
.loading-indicator {
  text-align: center;
  padding: 10px;
  font-size: 16px;
  color: gray;
}
</style>
