<template>
  <div class="wrapper">
    <div class="video-page">
      <div class="video-container">
        <iframe
          ref="videoPlayer"
          width="100%"
          height="500px"
          :src="`https://www.youtube.com/embed/${videoId}?enablejsapi=1`"
          frameborder="0"
          allowfullscreen
        ></iframe>

        <!-- Button to toggle the timer container -->
        <button v-if="!isTimerVisible" @click="toggleTimer" class="toggle-timer-btn">
           Show Timer
        </button>

        <!-- Timer container, visible only when isTimerVisible is true -->
        <div v-if="isTimerVisible" class="timer-container">
          <h3>Video Timer</h3>
          <div>
            <select v-model.number="hours" :disabled="isRunning">
              <option v-for="hour in hoursOptions" :key="hour" :value="hour">{{ hour }}</option>
            </select>
            <label style="margin-right: 12px">Hours</label>

            <select v-model.number="minutes" :disabled="isRunning">
              <option v-for="minute in minutesOptions" :key="minute" :value="minute">{{ minute }}</option>
              <option :key="10" :value="10">Hello Gabby :p</option>
            </select>
            <label>Minutes</label>
          </div>

          <div>
            <button @click="startTimer" :disabled="isRunning">Start</button>
            <button @click="stopTimer" :disabled="!isRunning">Stop</button>
            <button @click="resetTimer" :disabled="isRunning">Reset</button>
          </div>
          <p style="margin-bottom: 0">Timer: {{ formatTime(time) }}</p>
          <div v-if="isTimerVisible" style="display: flex; justify-content: center">
            <button style="margin: 0;" @click="toggleTimer" class="toggle-timer-btn">
             Hide Timer
            </button>
          </div>
        </div>
      </div>
      <div class="related-videos">
        <h2>Related Videos</h2>
        <div v-if="relatedVideos.length">
          <div v-for="video in relatedVideos" :key="video.id">
            <VideoCard :video="video" @click="goToVideo(video.id)" />
          </div>
        </div>
        <div v-else>
          <p>No related videos.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { getVideoDetails, getRelatedVideosByCategory } from "@/api/youtube";
import VideoCard from "@/components/VideoCard.vue";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup() {
    const hours = ref(0);
    const minutes = ref(0);
    const time = ref(0);
    const isRunning = ref(false);
    const isTimerVisible = ref(true); // Added to track visibility
    let interval = null;
    const videoPlayer = ref(null); // Ref for the iframe

    // Options for hours and minutes
    const hoursOptions = [1, 2, 3, 4, 5];
    const minutesOptions = [1, 5, 15, 30, 45];

    const startTimer = () => {
      if (time.value === 0) {
        time.value = hours.value * 3600 + minutes.value * 60;
      }
      isRunning.value = true;
      interval = setInterval(() => {
        if (time.value > 0) {
          time.value--;
        } else {
          stopTimer();
          pauseVideo(); // Pause the video when the timer ends
        }
      }, 1000);
    };

    const stopTimer = () => {
      clearInterval(interval);
      isRunning.value = false;
    };

    const resetTimer = () => {
      stopTimer();
      time.value = 0;
      hours.value = 0;
      minutes.value = 0;
    };

    const formatTime = (timeInSeconds) => {
      const hrs = Math.floor(timeInSeconds / 3600);
      const mins = Math.floor((timeInSeconds % 3600) / 60);
      const secs = timeInSeconds % 60;
      return `${hrs.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
    };

    // Pause the video
    const pauseVideo = () => {
      const player = videoPlayer.value;
      if (player) {
        player.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', "*");
      }
    };

    // Toggle the visibility of the timer container
    const toggleTimer = () => {
      isTimerVisible.value = !isTimerVisible.value;
    };

    return {
      hours,
      minutes,
      time,
      isRunning,
      startTimer,
      stopTimer,
      resetTimer,
      formatTime,
      videoPlayer, // Expose the video player ref
      hoursOptions,
      minutesOptions,
      isTimerVisible, // Expose the timer visibility state
      toggleTimer, // Expose the toggle function
    };
  },
  data() {
    return {
      videoDetails: {},
      relatedVideos: [],
    };
  },
  computed: {
    videoId() {
      return this.id;
    },
  },
  methods: {
    async fetchVideoDetails() {
      this.videoDetails = await getVideoDetails(this.videoId);
      await this.fetchRelatedVideos();
    },
    async fetchRelatedVideos() {
      if (this.videoDetails && this.videoDetails.snippet) {
        const categoryId = this.videoDetails.snippet.categoryId;
        this.relatedVideos = await getRelatedVideosByCategory(categoryId);
      }
    },
    goToVideo(videoId) {
      this.$router.push(`/video/${videoId}`);
    },
  },
  mounted() {
    this.fetchVideoDetails();
  },
  components: {
    VideoCard,
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  justify-content: center;
}
.video-page {
  display: flex;
  width: 100%;
  max-width: 1200px;
}
@media (max-width: 812px) {
  .video-page {
    flex-direction: column;
  }
  .video-container {
    align-self: flex-start;
  }
  .related-videos {
    padding-left: 0px !important;
  }
  .video-container {
    top: 56px !important;
    align-self: stretch !important;
  }
}
.video-container {
  flex: 70%;
  position: sticky;
  top: 80px;
  align-self: auto;
  background: #292935;
  padding-top: 20px;
  align-self: flex-start;
}
iframe {
  height: auto;
  width: 100%;
  aspect-ratio: 16 / 9;
}
.timer-container {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.timer-container h3 {
  margin-top: 0;
}
.timer-container input,
.timer-container select {
  width: 60px;
  margin-right: 10px;
}
.timer-container button {
  margin-right: 10px;
  margin-top: 10px;
}
.related-videos {
  flex: 30%;
  padding-left: 20px;
}
input {
  color: black;
  background: #c6c2c2;
  border: #c6c2c2;
}
button,
select {
  color: black;
}
.toggle-timer-btn {
  margin: 10px 0; /* Style for the toggle button */
}
</style>
