<template>
  <div class="video-list">
    <VideoCard v-for="video in videos" :key="video.id" :video="video" />
  </div>
</template>

<script>
import VideoCard from './VideoCard.vue';

export default {
  props: {
    videos: Array,
  },
  components: {
    VideoCard,
  },
};
</script>

<style scoped>
.video-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
}
</style>
