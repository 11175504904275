<template>
  <div class="wrapper">
    <nav class="navbar">
      <router-link to="/" class="logo"> Home </router-link>

      <!-- Sun Button -->
      <div class="brightness-container">
        <button @click="toggleDropdown" class="brightness-button">☀️</button>
        <div v-if="isDropdownVisible" class="dropdown">
          <label for="brightness">Brightness:</label>
          <select v-model.number="brightness" @change="adjustBrightness" id="brightness">
            <option v-for="level in brightnessLevels" :key="level" :value="level">{{ level }}%</option>
          </select>
        </div>
      </div>

      <div class="search">
        <input v-model="query" @keyup.enter="searchVideos" placeholder="Search" />
        <button @click="searchVideos">Search</button>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      query: "",
      isDropdownVisible: false, // State for dropdown visibility
      brightness: 100, // Default brightness level
      brightnessLevels: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100], // Brightness levels
    };
  },
  methods: {
    searchVideos() {
      // Navigate to the search results page with the query
      if (this.query) {
        this.$router.push({ path: "/search", query: { q: this.query } });
      }
    },
    toggleDropdown() {
      // Toggle the visibility of the dropdown
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    adjustBrightness() {
      // Set the brightness of the content element
      const contentElement = document.querySelector('.content');
      if (contentElement) {
        contentElement.style.filter = `brightness(${this.brightness}%)`;
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  background-color: #191920;
  display: flex;
  justify-content: center;
  z-index: 999;
}

/* Style die Navigationsleiste ähnlich wie YouTube */
.navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  max-width: 1200px;
  width: 100%;
}
.search {
  display: flex;
  align-items: center; /* Vertikale Ausrichtung */
  flex-wrap: wrap;
}
.search input {
  padding: 10px;
  width: 300px;
  color: black;
  background: #c6c2c2;
  border: #c6c2c2;
  border-radius: 4px 0 0 4px;
  max-width: 100%;
}
@media (max-width: 812px) {
  .search input {
    width: 180px;
  }
}
@media (max-width: 450px) {
  .search input {
    width: 100px;
  }
}
.search button {
  padding: 10px 15px;
  background-color: #ff4040; /* Rote Hintergrundfarbe für den Button */
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 0 4px 4px 0; /* Abgerundete Ecken für den Button */
  transition: background-color 0.3s;
}
.search button:hover {
  background-color: #ff3333; /* Dunklerer Rotton beim Hover */
}

/* Styles for brightness button and dropdown */
.brightness-container {
  position: relative; /* Position relative for dropdown */
  margin: 0 15px; /* Space between elements */
}
.brightness-button {
  background: none;
  border: none;
  font-size: 24px; /* Size of the sun icon */
  cursor: pointer;
}
.dropdown {
  position: absolute;
  background: white; /* Dropdown background */
  border: 1px solid #ccc; /* Border for dropdown */
  border-radius: 4px;
  padding: 10px;
  z-index: 1000; /* Ensure dropdown appears on top */
}
.dropdown label {
  display: block; /* Make the label block level */
  margin-bottom: 5px; /* Space below the label */
  color: black;
}
.dropdown select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: black;
}
</style>
