import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import VideoDetail from "@/views/VideoDetail.vue"; // Neue Seite für die Videodetails
import SearchResults from "@/views/SearchResults.vue"; // Importiere die neue Suchergebnisseite

const routes = [
  { path: "/", component: Home },
  { path: "/video/:id", component: VideoDetail, props: true }, // Dynamische Route für das Video
  { path: "/search", component: SearchResults }, // Route für die Suchergebnisse
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
