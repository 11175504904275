import axios from "axios";

const API_KEY = process.env.VUE_APP_YOUTUBE_API_KEY;


const youtube = axios.create({
  baseURL: "https://www.googleapis.com/youtube/v3",
  params: {
    part: "snippet",
    maxResults: 10, // Du kannst die Anzahl der Videos anpassen
    key: API_KEY,
  },
});

export default youtube;

// Funktion zum Abrufen der populären Videos
export const getPopularVideos = async (pageToken = '') => {
  return youtube.get("/videos", {
    params: {
      chart: "mostPopular", // Beliebteste Videos
      regionCode: "US", // Optional: Region (z.B. DE für Deutschland)
      maxResults: 20,
      pageToken, // Füge den nextPageToken hier hinzu
    },
  });
};


// Funktion zum Abrufen der Video-Details
export const getVideoDetails = async (videoId) => {
  try {
    const response = await youtube.get("/videos", {
      params: {
        part: "snippet",
        id: videoId,
      },
    });
    return response.data.items[0]; // Video-Details zurückgeben
  } catch (error) {
    console.error("Fehler beim Abrufen der Video-Details:", error);
    throw error;
  }
};

// Funktion zum Abrufen der verwandten Videos basierend auf der videoCategoryId
export const getRelatedVideosByCategory = async (categoryId) => {
  try {
    const response = await youtube.get("/search", {
      params: {
        part: "snippet",
        type: "video",
        videoCategoryId: categoryId, // Suche nach Videos in derselben Kategorie
        maxResults: 10, // Anzahl der Ergebnisse anpassen
      },
    });
    return response.data.items; // Rückgabe der verwandten Videos
  } catch (error) {
    console.error("Fehler beim Abrufen verwandter Videos:", error);
    throw error;
  }
};
