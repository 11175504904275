<template>
  <div @scroll="handleScroll" class="video-container">
    <VideoList :videos="results" />
    <div v-if="loading" class="loading-indicator">Loading more videos...</div>
  </div>
</template>

<script>
import VideoList from "@/components/VideoList.vue"; // Importiere die VideoList-Komponente
import youtube from "@/api/youtube"; // Importiere die youtube API-Instanz

export default {
  components: {
    VideoList,
  },
  data() {
    return {
      query: this.$route.query.q || "", // Initialisiere die query von der Route
      results: [], // Array für die Suchergebnisse
      loading: false, // Füge die loading-Property hinzu
    };
  },
  methods: {
    async fetchResults() {
      if (this.query) {
        this.results = []; // Setze die Ergebnisse zurück
        this.loading = true; // Setze loading auf true, bevor die API aufgerufen wird
        try {
          console.log("GET NEW SEARCH");
          const response = await youtube.get("/search", {
            params: {
              q: this.query,
              maxResults: 20,
            },
          });
          this.results = response.data.items; // Setze die Ergebnisse
        } catch (error) {
          console.error("Fehler beim Abrufen der Suchergebnisse:", error);
        } finally {
          this.loading = false; // Setze loading wieder auf false
        }
      }
    },
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      // Wenn der Benutzer nach unten scrollt, kannst du hier zusätzliche Ergebnisse laden
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        this.loadMoreResults();
      }
    },
    async loadMoreResults() {
      this.loading = true; // Setze loading auf true, während du mehr Ergebnisse lädst
      try {
        const response = await youtube.get("/search", {
          params: {
            q: this.query,
            maxResults: 20,
            // Hier kannst du pageToken oder ähnliches hinzufügen, um weitere Seiten abzurufen
          },
        });
        this.results.push(...response.data.items); // Füge die neuen Ergebnisse hinzu
      } catch (error) {
        console.error("Fehler beim Laden zusätzlicher Ergebnisse:", error);
      } finally {
        this.loading = false; // Setze loading wieder auf false
      }
    },
  },
  mounted() {
    this.fetchResults(); // Ergebnisse abrufen, wenn die Komponente gemountet wird
  },
  watch: {
    // Beobachte Änderungen an der Route
    '$route.query.q'(newQuery) {
      this.query = newQuery; // Aktualisiere die query, wenn sich die Route ändert
      this.fetchResults(); // Führe fetchResults aus, wenn sich die query ändert
    },
  },
};
</script>

<style scoped>
h1 {
  margin-bottom: 20px;
}
.video-container {
  overflow-y: auto; /* Scrollbar aktivieren, wenn der Container zu hoch ist */
  height: 100vh; /* Container-Höhe anpassen */
}
.loading-indicator {
  text-align: center; /* Zentriere den Text */
  margin-top: 20px; /* Abstand nach oben */
}
</style>
