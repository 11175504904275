<template>
  <div id="app">
    <div class="container">
      <NavBar />
      <div class="content" ref="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default {
  name: "App",
  components: {
    NavBar, // Header-Komponente importieren
  },
};
</script>

<style>
/* Globale Styles */
body {
  font-family: Arial, sans-serif;
}
html {
  background: #292935;
}
* {
  color: #e1dfdf;
}
.content {
  margin-top: 80px;
}
@media (max-width: 812px) {
  .content {
    margin-top: 56px;
  }
}
</style>
