<template>
  <div class="video-card" @click="goToVideo">
    <img :src="video.snippet.thumbnails.medium.url" alt="Video Thumbnail" />
    <div class="video-info">
      <h3>{{ video.snippet.title }}</h3>
      <p>{{ video.snippet.channelTitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    video: Object,
  },
  methods: {
    goToVideo() {
      let videoId = typeof this.video.id === "object" ? this.video.id.videoId : this.video.id;
      this.$router.push(`/video/${videoId}`); // Navigiere zur Videoseite
    },
  },
};
</script>

<style scoped>
.video-card {
  flex: 1 1 calc(20% - 20px);
  margin: 10px;
  cursor: pointer;
}
@media (max-width: 994px) {
  .video-card {
     flex: 1 1 calc(25% - 20px);
  }
}
@media (max-width: 770px) {
  .video-card {
    flex: 1 1 calc(33% - 20px);
  }
}
@media (max-width: 632px) {
  .video-card {
    flex: 1 1 calc(50% - 20px);
  }
}
@media (max-width: 481px) {
  .video-card {
    flex: 1 1 calc(100% - 20px);
  }
}
.video-card img {
  width: 100%;
  height: auto;
}
.video-info h3 {
  font-size: 14px;
}
</style>
